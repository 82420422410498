import React, { useEffect, useState } from 'react';
import { LazyLoadImage } from 'react-lazy-load-image-component';
import Lightbox from 'react-image-lightbox';
import { useTheme } from '@mui/material/styles';
import useMediaQuery from '@mui/material/useMediaQuery';
import Box from '@mui/material/Box';
import ImageList from '@mui/material/ImageList';
import ImageListItem from '@mui/material/ImageListItem';
import Button from '@mui/material/Button';

import Grid from '@mui/material/Grid';
import Main from 'layouts/Main';

// import p1 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/1.png';
// import p2 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/2.png';
// import p3 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/3.png';
// import p4 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/4.png';
// import p5 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/5.png';
// import p6 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/6.png';
// import p7 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/7.jpg';
// import p8 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/8.png';
// import p9 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/9.png';
// import p10 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/10.png';
// import p11 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/11.png';
// import p12 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/12.png';
// import p13 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/13.png';
// import p14 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/14.png';
// import p15 from 'assests/Photos/Clubs/LitreryClub/StoryTelling/15.png';

import SideBar1 from 'views/LiteraryClub/SideBar1';




import { Typography } from '@mui/material';
import Container from 'components/Container';
import SidebarArticles from 'views/ClassAssembly3E/components/SidebarArticles/SidebarArticles';

const StoryTelling = () => {
  const theme = useTheme();
  const [currentImage, setCurrentImage] = useState(0);
  const [viewerIsOpen, setViewerIsOpen] = useState(false);

  const openLightbox = (index) => {
    setCurrentImage(index);
    setViewerIsOpen(true);
  };

  const closeLightbox = () => {
    setCurrentImage(0);
    setViewerIsOpen(false);
  };

  const isMd = useMediaQuery(theme.breakpoints.up('md'), {
    defaultMatches: true,
  });

  const [config, setConfig] = useState({ base_image_archive_url: '' });
  useEffect(() => {
    fetch('/config.json')  // For Gatsby, this will resolve to /config.json from the static folder
      .then(response => response.json())
      .then(data => setConfig(data))
      .catch(error => console.error('Error fetching config:', error));
  }, []);
  
  const p1 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/1.webp`;
  const p2 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/2.webp`;
  const p3 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/3.webp`;
  const p4 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/4.webp`;
  const p5 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/5.webp`;
  const p6 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/6.webp`;
  const p7 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/7.webp`;
  const p8 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/8.webp`;
  const p9 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/9.webp`;
  const p10 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/10.webp`;
  const p11 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/11.webp`;
  const p12 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/12.webp`;
  const p13 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/13.webp`;
  const p14 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/14.webp`;
  const p15 = `${config.base_image_archive_url}/academic-year-2022-23/home/events-activities/association/LitreryClub/StoryTelling/15.webp`;
  

  const photos = [
    {
      src: p1,
      source: p1,
      rows: 1,
      cols: 1,
    },
    {
      src: p2,
      source:p2,
      rows: 1,
      cols: 1,
    },
    {
      src: p3,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
      src: p4,
      source: p4,
      rows: 1,
      cols: 1,
    },
    {
        src: p5,
        source: p5,
        rows: 1,
        cols: 1,
      },
      {
        src: p6,
        source: p6,
        rows: 1,
        cols: 1,
      },
      {
        src: p7,
        source:p7,
        rows: 1,
        cols: 1,
      },
      {
        src: p8,
        source: p8,
        rows: 1,
        cols: 1,
      },
      {
        src: p9,
        source: p9,
        rows: 1,
        cols: 1,
      },
      {
          src: p10,
          source: p10,
          rows: 1,
          cols: 1,
        },
        {
            src: p11,
            source: p11,
            rows: 1,
            cols: 1,
          },
          {
            src: p12,
            source:p12,
            rows: 1,
            cols: 1,
          },
          {
            src: p13,
            source: p13,
            rows: 1,
            cols: 1,
          },
          {
            src: p14,
            source: p14,
            rows: 1,
            cols: 1,
          },
          {
              src: p15,
              source: p15,
              rows: 1,
              cols: 1,
            },

  ];

  return (
    <Main>
        <Container>
    <Grid container spacing={4}>
            <Grid item xs={12} md={8}>
    <Box>
        
      <Box >
      <Typography variant='h4' align='center'>
      Story Telling & Rhyme Time
        </Typography>
        <Typography
        fontSize = "x-small"
        ><br/><br/>
        Date: 27-1-2023 Class: Montessori & Kindergarten
        </Typography>

        <br />
        <Typography variant={'subtitle1'} align={'justify'}>
        “A story can imbibe the right value in the most impressionable age”<br/>
        Since time immemorial, storytelling has been an integral part of our childhood. Young children have an
innate love for stories. Storytelling is a unique way that encourages students to use their imagination
and creativity. It increases willingness to communicate their thoughts and feelings and most
importantly enhances their listening skills. <br/><br/>
To incite curiosity and love for reading and recitation, a Story Telling & Rhyme Time activity was
conducted on Friday, 27 January 2023 for the students of Pre-Primary at National Public School
Yeshwanthpur. The students participated in Rhyme Recitations, Story Telling and Story Reading with
much enthusiasm and excitement. The youngest of the tots, the Pre-K and Montessori-I students
came dressed in colourful attire representing the characters of a rhyme and presented their recitation
with rhythm and action. It was a perfect start for the day with the sound of their pleasant music. The
students of KG-I and Montessori-II presented interesting stories using props. The students narrated the
stories with a lot of expression and intonation making the session an interesting one. The students of
KG-II and Montessori-III read story books of their choice and narrated it with confidence.<br/><br/>
The young tale tellers well prepared with their props and energetic performances, shared great stories
enthusiastically. The audience were given food for thought by certain narratives, while moral principles
were reinforced by others. The students participated with their tremendous effort to make this event a
successful one.<br/></Typography>
      </Box>
      <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
       The best way to teach a child is by telling a STORY…
<br/>
        </Typography>

      <Box>
        <ImageList
          variant="quilted"
          cols={3}
          rowHeight={isMd ? 300 : 200}
          gap={isMd ? 16 : 4}
        >
          {photos.map((item, i) => (
            <ImageListItem key={i} cols={item.cols} rows={item.rows}>
              <LazyLoadImage
                height={'100%'}
                width={'100%'}
                src={item.src}
                alt="..."
                effect="blur"
                onClick={() => openLightbox(i)}
                style={{
                  objectFit: 'cover',
                  filter:
                    theme.palette.mode === 'dark' ? 'brightness(0.7)' : 'none',
                  cursor: 'poiner',
                  borderRadius: 8,
                }}
              />
            </ImageListItem>
          ))}
        </ImageList>
      </Box>
      {viewerIsOpen && (
        <Lightbox
          mainSrc={photos[currentImage].src}
          nextSrc={photos[(currentImage + 1) % photos.length].src}
          prevSrc={
            photos[(currentImage + photos.length - 1) % photos.length].src
          }
          onCloseRequest={() => closeLightbox()}
          onMovePrevRequest={() =>
            setCurrentImage((currentImage + photos.length - 1) % photos.length)
          }
          onMoveNextRequest={() =>
            setCurrentImage((currentImage + 1) % photos.length)
          }
          reactModalStyle={{ overlay: { zIndex: 1500 } }}
        />
      )}
    </Box>
    <Typography
          variant={'h4'}
          color={'primary'}
          align={'center'}
          fontWeight={'normal'}
        ><br/>
      “There’s always room for a story that can transport people to another place.”
<br/>
        </Typography>
    </Grid>
    <Grid item xs={12} md={4}>
             
             <Box marginBottom={4}>
               <SideBar1 />
             </Box>
          
           {/* <SidebarNewsletter /> */}
         </Grid>
    </Grid> 
    </Container>
    </Main>
   
  );
};

export default StoryTelling;